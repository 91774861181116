import { Paper, useTheme } from "@mui/material";
import {
  DOCUMENT_DISABILITY_STATEMENT_PROVIDER_SEARCH,
  DOCUMENT_EASY_LANGUAGE,
  DOCUMENT_PRIVACY_POLICY,
  DOCUMENT_SIGN_LANGUAGE,
  DOCUMENT_TERMS_AND_CONDITIONS,
} from "core/consts";
import { activateProviderSearchTableOfContents } from "core/model/utils/featureFlags";
import { IconType, LegalDocumentType } from "core/types";
import { LogoWrapper } from "ds_legacy/components/AppBar";
import LinkV2, {
  ExternalLinkType,
  useExternalLinkLabel,
} from "ds_legacy/components/LinkV2";
import { useMessengerTabs } from "ds_legacy/components/Tabs/Messenger";
import {
  Divider,
  HorizontalLayout,
  VerticalLayout,
} from "ds_legacy/materials/layouts";
import { dp, margin, padding, sizing } from "ds_legacy/materials/metrics";
import { SHADOW_DP8 } from "ds_legacy/materials/shadows";
import {
  Body,
  FONT_SIZE_14,
  FONT_SIZE_16,
  FONT_WEIGHT_BOLD,
  LINE_HEIGHT_16,
} from "ds_legacy/materials/typography";
import { usePrint } from "dsl/atoms/Contexts";
import Image from "dsl/atoms/Image";
import { useLegalDocuments } from "dsl/atoms/LegalDocuments";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useProvidersearchNavigationUrls } from "dsl/hooks/useNavigationHandlers";
import { MailIcon } from "lucide-react";
import { To } from "react-router-dom";
import { useTranslations } from "translations";

const LINKS = {
  stmpg: "https://www.stmgp.bayern.de/",
  lfp: "https://www.lfp.bayern.de/",
  bkg: "https://www.bkg-online.de/",
  recareHome: "https://recaresolutions.com",
  recareImprint: "https://recaresolutions.com/impressum/",
  recarePrivacyPolicy: DOCUMENT_PRIVACY_POLICY,
  recareTermsOfUse: DOCUMENT_TERMS_AND_CONDITIONS,
  accessibilityDeclaration: DOCUMENT_DISABILITY_STATEMENT_PROVIDER_SEARCH,
  easyLanguage: DOCUMENT_EASY_LANGUAGE,
  signLanguage: DOCUMENT_SIGN_LANGUAGE,
  accessibilityCertificate:
    "https://report.bitvtest.de/ba8a7cd7-4ebb-4b40-9374-e6fd7b698ba1.html",
};

const Sponsors = () => {
  const { isDesktop } = useMedia();
  const translations = useTranslations();
  const externalLinkLabel = useExternalLinkLabel();

  return (
    <HorizontalLayout
      gap={isDesktop ? dp(80) : sizing(2)}
      flexWrap="wrap"
      justify={isDesktop ? "normal" : "flex-end"}
      overflow="visible"
    >
      <LinkV2 href={LINKS.stmpg} target="_blank">
        <Image
          style={{ height: isDesktop ? sizing(9) : sizing(7.5) }}
          path="bcp-stmgp-logo.svg"
          alt={externalLinkLabel(
            translations.providersearch.footer.altTextLogoStaatsministerium,
          )}
        />
      </LinkV2>
      <LinkV2 href={LINKS.lfp} target="_blank" alignSelf="flex-end">
        <Image
          style={{
            height: isDesktop ? sizing(7.25) : sizing(5.75),
          }}
          path="bcp-lfp-logo.svg"
          alt={externalLinkLabel(
            translations.providersearch.footer.altTextLogoLandesamt,
          )}
        />
      </LinkV2>
    </HorizontalLayout>
  );
};

const Participants = () => {
  const theme = useTheme();
  const translations = useTranslations();
  const externalLinkLabel = useExternalLinkLabel();

  return (
    <VerticalLayout margin={margin(3, 0)} overflow="visible">
      <Body
        as="p"
        fontSize={FONT_SIZE_16}
        fontWeight={FONT_WEIGHT_BOLD}
        margin={margin(0, 0, 1)}
      >
        {translations.providersearch.footer.inCollaborationWith}
      </Body>
      <Body as="p" fontSize={FONT_SIZE_16} margin={margin(0, 0, 0.5)}>
        {translations.providersearch.footer.partnersInsurance}
      </Body>
      <div>
        <LinkV2
          aria-label={externalLinkLabel(
            translations.providersearch.footer.partnersHospitals,
          )}
          href={LINKS.bkg}
          target="_blank"
          sx={{
            color: theme.palette.common.black,
            fontWeight: FONT_WEIGHT_BOLD,
            fontSize: FONT_SIZE_16,
            margin: margin(0),
          }}
        >
          {translations.providersearch.footer.partnersHospitals}
        </LinkV2>
      </div>
    </VerticalLayout>
  );
};

const DevelopmentAndImplementation = () => {
  const externalLinkLabel = useExternalLinkLabel();
  const translations = useTranslations();
  const { isDesktop } = useMedia();

  return (
    <VerticalLayout margin={margin(3, 0)} overflow="visible">
      <Body
        as="p"
        fontSize={FONT_SIZE_16}
        fontWeight={FONT_WEIGHT_BOLD}
        margin={margin(0, 0, 2)}
      >
        {translations.providersearch.footer.developedBy}
      </Body>
      <LinkV2
        href={LINKS.recareHome}
        target="_blank"
        sx={{ width: "fit-content" }}
      >
        <Image
          style={{
            height: isDesktop ? sizing(8) : sizing(6),
            alignSelf: "flex-end",
          }}
          path="logo/svg/recare_logo_rgb_master.svg"
          alt={externalLinkLabel(
            translations.providersearch.footer.altTextRecareLogo,
          )}
        />
      </LinkV2>
    </VerticalLayout>
  );
};

type LinkItem = {
  ariaLabel?: string;
  icon?: IconType;
  label: string;
  type?: ExternalLinkType;
} & (
  | {
      href?: never;
      to: To;
    }
  | { href: string; to?: never }
  | { href?: never; to?: never }
);

const FooterListItem = ({ index, item }: { index: number; item: LinkItem }) => {
  const theme = useTheme();
  const Icon = item.icon;

  if (item.to || item.href) {
    return (
      <LinkV2
        aria-label={item.ariaLabel}
        target={item.href ? "_blank" : undefined}
        sx={{
          display: "flex",
          gap: sizing(1),
          alignItems: "center",
          justifyContent: "center",
          color: theme.palette.common.black,
          margin: 0,
        }}
        type={item.type}
        {...(item.to
          ? { to: item.to as string }
          : { href: item.href as string })}
      >
        {Icon ? (
          <Icon
            style={{
              fontSize: FONT_SIZE_16,
              color: theme.palette.common.black,
            }}
            size={FONT_SIZE_16}
          />
        ) : null}
        <Body
          fontWeight={FONT_WEIGHT_BOLD}
          fontSize={FONT_SIZE_14}
          margin={margin(0)}
        >
          {item.label}
        </Body>
      </LinkV2>
    );
  }

  return (
    <Body
      as="p"
      fontSize={FONT_SIZE_14}
      margin={margin(0, 4, 1, 0)}
      key={index}
    >
      {item.label}
    </Body>
  );
};

const LinksAndContactInfo = () => {
  const externalLinkLabel = useExternalLinkLabel();
  const providerSearchNavigationUrls = useProvidersearchNavigationUrls();
  const translations = useTranslations();
  const { getDocumentUrl } = useLegalDocuments();

  const items: Array<LinkItem> = [
    {
      label: translations.providersearch.footer.impressum,
      ariaLabel: externalLinkLabel(
        translations.providersearch.footer.impressum,
      ),
      href: LINKS.recareImprint,
    },
    {
      label: translations.providersearch.footer.privacyPolicy,
      ariaLabel: externalLinkLabel(
        translations.providersearch.footer.privacyPolicy,
        "pdf",
      ),
      href: getDocumentUrl(LINKS.recarePrivacyPolicy as LegalDocumentType),
    },
    {
      label: translations.providersearch.footer.termsOfUse,
      ariaLabel: externalLinkLabel(
        translations.providersearch.footer.termsOfUse,
        "pdf",
      ),
      href: getDocumentUrl(LINKS.recareTermsOfUse as LegalDocumentType),
    },
    ...(activateProviderSearchTableOfContents
      ? [
          {
            label: translations.providersearch.footer.tableOfContent,
            to: providerSearchNavigationUrls.goToTableOfContents(),
          },
        ]
      : []),
    {
      label: translations.providersearch.footer.accessibilityDeclaration,
      ariaLabel: externalLinkLabel(
        translations.providersearch.footer.accessibilityDeclaration,
        "pdf",
      ),
      href: getDocumentUrl(LINKS.accessibilityDeclaration as LegalDocumentType),
    },
    {
      label: translations.providersearch.footer.easyLanguage,
      ariaLabel: externalLinkLabel(
        translations.providersearch.footer.easyLanguage,
        "pdf",
      ),
      href: getDocumentUrl(LINKS.easyLanguage as LegalDocumentType),
    },
    {
      label: translations.providersearch.footer.signLanguage,
      ariaLabel: externalLinkLabel(
        translations.providersearch.footer.signLanguage,
        "pdf",
      ),
      href: getDocumentUrl(LINKS.signLanguage as LegalDocumentType),
    },
    {
      label: translations.providersearch.footer.reportBarrier,
      ariaLabel: externalLinkLabel(
        translations.providersearch.footer.reportBarrier,
        "email",
      ),
      href: translations.providersearch.footer.reportBarrierLink,
      icon: MailIcon,
    },
    {
      label: translations.providersearch.footer.reportContent,
      ariaLabel: externalLinkLabel(
        translations.providersearch.footer.reportContent,
        "email",
      ),
      href: translations.providersearch.footer.reportContentLink,
      icon: MailIcon,
    },
    {
      label: translations.providersearch.footer.phoneNumberLabel,
      ariaLabel: externalLinkLabel(
        translations.providersearch.footer.phone,
        "phone",
      ),
      href: `tel:${translations.providersearch.footer.phone}`,
      type: "phone",
    },
    { label: translations.providersearch.footer.emailAddress },
  ];

  return (
    <HorizontalLayout
      as="ul"
      margin={margin(3, 0, 0)}
      flexWrap="wrap"
      style={{ listStyle: "none", overflow: "visible" }}
    >
      {items.map((item, index) => {
        return (
          <li key={index} style={{ margin: margin(0, 4, 1, 0) }}>
            <FooterListItem item={item} index={index} />
          </li>
        );
      })}
    </HorizontalLayout>
  );
};

const Copyright = () => {
  const translations = useTranslations();
  return (
    <Body
      as="p"
      fontSize={FONT_SIZE_14}
      margin={margin(0)}
      lineHeight={LINE_HEIGHT_16}
    >
      {translations.providersearch.footer.copyright}
    </Body>
  );
};

const AccessibilityCertificate = () => {
  const translations = useTranslations();
  const externalLinkLabel = useExternalLinkLabel();

  return (
    <LogoWrapper href={LINKS.accessibilityCertificate} target="_blank">
      <Image
        style={{ height: sizing(5) }}
        src="https://bitvtest.de/fileadmin/user_upload/bik-bitv-konform-h44px.svg"
        alt={externalLinkLabel(
          translations.providersearch.footer.altTextAccessibilityCertificate,
        )}
      />
    </LogoWrapper>
  );
};

const CopyrightAndAccessibilityCertificate = () => {
  const { isMobile } = useMedia();

  return (
    <HorizontalLayout
      style={{
        marginTop: isMobile ? sizing(2) : undefined,
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexWrap: "wrap-reverse",
        gap: sizing(4),
        overflow: "visible",
      }}
    >
      <Copyright />
      <AccessibilityCertificate />
    </HorizontalLayout>
  );
};

export const Footer = () => {
  const { isDesktop } = useMedia();
  const print = usePrint();
  const { activeTab } = useMessengerTabs();

  if (activeTab === "messenger" || print) return null;

  return (
    <Paper
      component="footer"
      sx={{
        boxShadow: SHADOW_DP8,
        padding: isDesktop ? padding(5, 5, 2, 5) : padding(3),
        overflow: "visible",
        "@media print": {
          display: "none",
          padding: "0",
        },
      }}
    >
      <VerticalLayout overflow="visible">
        <Sponsors />
        <Participants />
        <Divider />
        <DevelopmentAndImplementation />
        <Divider />
        <LinksAndContactInfo />
        <CopyrightAndAccessibilityCertificate />
      </VerticalLayout>
    </Paper>
  );
};
