import { useEnvContext } from "context/EnvContext";
import { APP_B2C } from "core/consts";
import { AppName, AppType } from "core/types";
import LanguageFab from "ds_legacy/components/FlagFab";
import { SpinnerPage } from "ds_legacy/components/Spinner";
import { margin } from "ds_legacy/materials/metrics";
import { usePrint } from "dsl/atoms/Contexts";
import PrintOverlay from "dsl/atoms/PrintOverlay";
import { useEnvironmentSwitcher } from "dsl/hooks";
import useLoadFavicon from "dsl/hooks/useLoadFavicon";
import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

export const Page = styled.div<{ app: AppType; print: boolean }>`
  box-sizing: border-box;
  display: ${({ print }) => (print ? "block" : "flex")};
  flex-direction: ${({ app }) => (app === APP_B2C ? "row" : "column")};
  flex: 1;
  min-height: 100vh;
  @media print {
    height: auto;
    display: block;
    margin: ${margin(0, 2)};
    min-height: auto;
    page-break-inside: avoid;
  }
`;

export default function RootPage({
  appName,
  children,
}: {
  appName: AppName;
  children?: React.ReactNode;
}) {
  const { app } = useEnvContext();
  const print = usePrint();

  useLoadFavicon();
  useEnvironmentSwitcher();

  return (
    <Page print={print} app={app}>
      <PrintOverlay />
      <LanguageFab />
      <Suspense
        fallback={
          <SpinnerPage id={`suspense-router-${appName}`} height="100%" />
        }
      >
        {children ? children : <Outlet />}
      </Suspense>
    </Page>
  );
}
